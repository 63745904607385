import React from "react";
import Footer from "../footer/Footer";

const Profile = () => {
  return (
    <div className="wrapper">
      <div className="main-panel">
        <div className="content">
          <div className="panel-header">
            <div className="page-inner py-5">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                <div>
                  <h2 className="pb-2 fw-bold">My Profile</h2>
                  <ul className="breadcrumbs">
                    <li className="nav-home">
                      <a href="/">
                        <i className="flaticon-home" />
                      </a>
                    </li>
                    <li className="separator">
                      <i className="flaticon-right-arrow" />
                    </li>
                    <li className="nav-item">
                      <a href="/">My Account</a>
                    </li>
                    <li className="separator">
                      <i className="flaticon-right-arrow" />
                    </li>
                    <li className="nav-item">
                      <a href="/">My Profile</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="page-inner mt--5">
            <div className="row">
              <div className="col-md-4 col-xl-3">
                <div className="card mb-3">
                  <div className="card-header">
                    <h5 className="card-title mb-0">Profile Details</h5>
                  </div>
                  <div className="card-body text-center">
                    <div className="avatar avatar-xxl">
                      <img
                        src="assets/img/jm_denis.jpg"
                        alt="..."
                        className="avatar-img rounded-circle"
                      />
                    </div>
                    <h5 className="card-title mb-0">Christina Mason</h5>
                    <div className="text-muted mb-2">Lead Developer</div>
                    <div>
                      <a className="btn btn-primary btn-sm" href="/">
                        Follow
                      </a>
                      <a className="btn btn-primary btn-sm" href="/">
                        <span data-feather="message-square" /> Message
                      </a>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="card-body">
                    <h5 className="h6 card-title">Skills</h5>
                    <a href="/" className="badge badge-primary mr-1 my-1">
                      HTML
                    </a>
                    <a href="/" className="badge badge-primary mr-1 my-1">
                      JavaScript
                    </a>
                    <a href="/" className="badge badge-primary mr-1 my-1">
                      Sass
                    </a>
                    <a href="/" className="badge badge-primary mr-1 my-1">
                      Angular
                    </a>
                    <a href="/" className="badge badge-primary mr-1 my-1">
                      Vue
                    </a>
                    <a href="/" className="badge badge-primary mr-1 my-1">
                      React
                    </a>
                    <a href="/" className="badge badge-primary mr-1 my-1">
                      Redux
                    </a>
                    <a href="/" className="badge badge-primary mr-1 my-1">
                      UI
                    </a>
                    <a href="/" className="badge badge-primary mr-1 my-1">
                      UX
                    </a>
                  </div>
                  <hr className="my-0" />
                  <div className="card-body">
                    <h5 className="h6 card-title">About</h5>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-1">
                        <span data-feather="home" className="feather-sm mr-1" />{" "}
                        Lives in <a href="/">San Francisco, SA</a>
                      </li>
                      <li className="mb-1">
                        <span
                          data-feather="briefcase"
                          className="feather-sm mr-1"
                        />{" "}
                        Works at <a href="/">GitHub</a>
                      </li>
                      <li className="mb-1">
                        <span
                          data-feather="map-pin"
                          className="feather-sm mr-1"
                        />{" "}
                        From <a href="/">Boston</a>
                      </li>
                    </ul>
                  </div>
                  <hr className="my-0" />
                  <div className="card-body">
                    <h5 className="h6 card-title">Elsewhere</h5>
                    <ul className="list-unstyled mb-0">
                      <li className="mb-1">
                        <span className="fas fa-globe fa-fw mr-1" />{" "}
                        <a href="/">staciehall.co</a>
                      </li>
                      <li className="mb-1">
                        <span className="fab fa-twitter fa-fw mr-1" />{" "}
                        <a href="/">Twitter</a>
                      </li>
                      <li className="mb-1">
                        <span className="fab fa-facebook fa-fw mr-1" />{" "}
                        <a href="/">Facebook</a>
                      </li>
                      <li className="mb-1">
                        <span className="fab fa-instagram fa-fw mr-1" />{" "}
                        <a href="/">Instagram</a>
                      </li>
                      <li className="mb-1">
                        <span className="fab fa-linkedin fa-fw mr-1" />{" "}
                        <a href="/">LinkedIn</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-xl-9">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">Public info</h5>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-group">
                            <label htmlFor="inputUsername">Username</label>
                            <input
                              type="text"
                              className="form-control"
                              id="inputUsername"
                              placeholder="Username"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="inputUsername">Biography</label>
                            <textarea
                              rows={2}
                              className="form-control"
                              id="inputBio"
                              placeholder="Tell something about yourself"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="text-center">
                            <div className="avatar avatar-xxl">
                              <img
                                src="assets/img/jm_denis.jpg"
                                alt="..."
                                className="avatar-img rounded-circle"
                              />
                            </div>
                            <div className="mt-2">
                              <span className="btn btn-primary">
                                <i className="fas fa-upload" /> Upload
                              </span>
                            </div>
                            <small>
                              For best results, use an image at least 128px by
                              128px in .jpg format
                            </small>
                          </div>
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Save changes
                      </button>
                    </form>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0">Private info</h5>
                  </div>
                  <div className="card-body">
                    <form>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputFirstName">First name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputFirstName"
                            placeholder="First name"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="inputLastName">Last name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputLastName"
                            placeholder="Last name"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputEmail4">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Email"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputAddress">Address</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputAddress"
                          placeholder="1234 Main St"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="inputAddress2">Address 2</label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputAddress2"
                          placeholder="Apartment, studio, or floor"
                        />
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="inputCity">City</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputCity"
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="inputState">State</label>
                          <select id="inputState" className="form-control">
                            <option selected="">Choose...</option>
                            <option>...</option>
                          </select>
                        </div>
                        <div className="form-group col-md-2">
                          <label htmlFor="inputZip">Zip</label>
                          <input
                            type="text"
                            className="form-control"
                            id="inputZip"
                          />
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary">
                        Save changes
                      </button>
                    </form>
                  </div>
                
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      {/* Custom template | don't include it in your project! */}
      <div className="custom-template">
        <div className="title">Settings</div>
        <div className="custom-content">
          <div className="switcher">
            <div className="switch-block">
              <h4>Logo Header</h4>
              <div className="btnSwitch">
                <button
                  type="button"
                  className="changeLogoHeaderColor"
                  data-color="dark"
                />
                <button
                  type="button"
                  className="selected changeLogoHeaderColor"
                  data-color="blue"
                />
                <button
                  type="button"
                  className="changeLogoHeaderColor"
                  data-color="purple"
                />
                <button
                  type="button"
                  className="changeLogoHeaderColor"
                  data-color="light-blue"
                />
                <button
                  type="button"
                  className="changeLogoHeaderColor"
                  data-color="green"
                />
                <button
                  type="button"
                  className="changeLogoHeaderColor"
                  data-color="orange"
                />
                <button
                  type="button"
                  className="changeLogoHeaderColor"
                  data-color="red"
                />
                <button
                  type="button"
                  className="changeLogoHeaderColor"
                  data-color="white"
                />
                <br />
                <button
                  type="button"
                  className="changeLogoHeaderColor"
                  data-color="dark2"
                />
                <button
                  type="button"
                  className="changeLogoHeaderColor"
                  data-color="blue2"
                />
                <button
                  type="button"
                  className="changeLogoHeaderColor"
                  data-color="purple2"
                />
                <button
                  type="button"
                  className="changeLogoHeaderColor"
                  data-color="light-blue2"
                />
                <button
                  type="button"
                  className="changeLogoHeaderColor"
                  data-color="green2"
                />
                <button
                  type="button"
                  className="changeLogoHeaderColor"
                  data-color="orange2"
                />
                <button
                  type="button"
                  className="changeLogoHeaderColor"
                  data-color="red2"
                />
              </div>
            </div>
            <div className="switch-block">
              <h4>Navbar Header</h4>
              <div className="btnSwitch">
                <button
                  type="button"
                  className="changeTopBarColor"
                  data-color="dark"
                />
                <button
                  type="button"
                  className="changeTopBarColor"
                  data-color="blue"
                />
                <button
                  type="button"
                  className="changeTopBarColor"
                  data-color="purple"
                />
                <button
                  type="button"
                  className="changeTopBarColor"
                  data-color="light-blue"
                />
                <button
                  type="button"
                  className="changeTopBarColor"
                  data-color="green"
                />
                <button
                  type="button"
                  className="changeTopBarColor"
                  data-color="orange"
                />
                <button
                  type="button"
                  className="changeTopBarColor"
                  data-color="red"
                />
                <button
                  type="button"
                  className="changeTopBarColor"
                  data-color="white"
                />
                <br />
                <button
                  type="button"
                  className="changeTopBarColor"
                  data-color="dark2"
                />
                <button
                  type="button"
                  className="selected changeTopBarColor"
                  data-color="blue2"
                />
                <button
                  type="button"
                  className="changeTopBarColor"
                  data-color="purple2"
                />
                <button
                  type="button"
                  className="changeTopBarColor"
                  data-color="light-blue2"
                />
                <button
                  type="button"
                  className="changeTopBarColor"
                  data-color="green2"
                />
                <button
                  type="button"
                  className="changeTopBarColor"
                  data-color="orange2"
                />
                <button
                  type="button"
                  className="changeTopBarColor"
                  data-color="red2"
                />
              </div>
            </div>
            <div className="switch-block">
              <h4>Sidebar</h4>
              <div className="btnSwitch">
                <button
                  type="button"
                  className="selected changeSideBarColor"
                  data-color="white"
                />
                <button
                  type="button"
                  className="changeSideBarColor"
                  data-color="dark"
                />
                <button
                  type="button"
                  className="changeSideBarColor"
                  data-color="dark2"
                />
              </div>
            </div>
            <div className="switch-block">
              <h4>Background</h4>
              <div className="btnSwitch">
                <button
                  type="button"
                  className="changeBackgroundColor"
                  data-color="bg2"
                />
                <button
                  type="button"
                  className="changeBackgroundColor selected"
                  data-color="bg1"
                />
                <button
                  type="button"
                  className="changeBackgroundColor"
                  data-color="bg3"
                />
                <button
                  type="button"
                  className="changeBackgroundColor"
                  data-color="dark"
                />
              </div>
            </div>
          </div>
        </div>
        
      </div>
     
    </div>
  );
};

export default Profile;
