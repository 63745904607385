// import React from 'react'
// import { Link } from "react-router-dom";

// const Sidebar = () => {
//   return (
//     <div class="sidebar sidebar-style-2" data-background-color="dark">			
// 			<div class="sidebar-wrapper scrollbar scrollbar-inner">
// 				<div class="sidebar-content"
// 				>
// 					<div class="user">
// 						<div class="avatar-sm float-left mr-2">
// 							<img src="assets/img/profile.jpg" alt="..." class="avatar-img rounded-circle" />
// 						</div>
// 						<div class="info">
// 							<a data-toggle="collapse" href="#collapseExample" aria-expanded="true">
// 								<span>
// 									Hizrian
// 									<span class="user-level">Administrator</span>
// 									<span class="caret"></span>
// 								</span>
// 							</a>
// 							<div class="clearfix"></div>

// 							<div class="collapse in" id="collapseExample">
// 								<ul class="nav">
// 									<li>
// 										<Link to="/profile">
// 											<span class="link-collapse">My Profile</span>
// 										</Link>
// 									</li>
// 									<li>
// 										<a href="edit-profile.html">
// 											<span class="link-collapse">Edit Profile</span>
// 										</a>
// 									</li>
// 									<li>
// 										<a href="settings.html">
// 											<span class="link-collapse">Settings</span>
// 										</a>
// 									</li>
// 								</ul>
// 							</div>
// 						</div>
// 					</div>
// 					<ul class="nav nav-primary">
// 						<li class="nav-item active">
// 							<Link to="/">
// 								<i class="fas fa-home"></i>
// 								<p>Dashboard</p>
// 							</Link>
// 						</li>
// 						<li class="nav-section">
// 							<span class="sidebar-mini-icon">
// 								<i class="fa fa-ellipsis-h"></i>
// 							</span>
// 							<h4 class="text-section">Components</h4>
// 						</li>
// 						<li class="nav-item">
// 							<a data-toggle="collapse" href="#base">
// 								<i class="fas fa-layer-group"></i>
// 								<p>Manage User</p>
// 								<span class="caret"></span>
// 							</a>
// 							<div class="collapse" id="base">
// 								<ul class="nav nav-collapse">
// 									<li>
// 										<Link to="/createuser">
// 											<span class="sub-item">Create User</span>
// 										</Link>
// 									</li>
// 									<li>
// 										<a href="edit-user.html">
// 											<span class="sub-item">Edit User</span>
// 										</a>
// 									</li>
// 									<li>
// 										<Link to="/viewuser">
// 											<span class="sub-item">View User</span>
// 										</Link>
// 									</li>
// 									<li>
// 										<a href="delete-user.html">
// 											<span class="sub-item">Delete User</span>
// 										</a>
// 									</li>									
// 								</ul>
// 							</div>
// 						</li>
// 						<li class="nav-item">
// 							<a data-toggle="collapse" href="#sidebarLayouts">
// 								<i class="fas fa-th-list"></i>
// 								<p>Manage Transactions</p>
// 								<span class="caret"></span>
// 							</a>
// 							<div class="collapse" id="sidebarLayouts">
// 								<ul class="nav nav-collapse">
// 									<li>
// 										<a href="all-transactions.html">
// 											<span class="sub-item">All Transactions</span>
// 										</a>
// 									</li>									
// 								</ul>
// 							</div>
// 						</li>
// 						<li class="nav-item">
// 							<a data-toggle="collapse" href="#tables">
// 								<i class="fas fa-table"></i>
// 								<p>License</p>
// 								<span class="caret"></span>
// 							</a>
// 							<div class="collapse" id="tables">
// 								<ul class="nav nav-collapse">
// 									<li>
// 										<a href="create-license.html">
// 											<span class="sub-item">Create License</span>
// 										</a>
// 									</li>
// 									<li>
// 										<a href="send-license.html">
// 											<span class="sub-item">Send License</span>
// 										</a>
// 									</li>
// 								</ul>
// 							</div>
// 						</li>


// 					</ul>
// 				</div>
// 			</div>
// 		</div>
//   )
// }

// export default Sidebar



import React, { useState } from 'react';
import { Link } from "react-router-dom";

const Sidebar = ({ data }) => {

  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (index) => {
    setActiveDropdown(activeDropdown === index ? null : index);
  };

  return (
    <div className="sidebar sidebar-style-2" data-background-color="dark">
      <div className="sidebar-wrapper scrollbar scrollbar-inner">
        <div className="sidebar-content">
          <div className="user">
            <div className="avatar-sm float-left mr-2">
              <img src="/assets/img/profile.jpg" alt="..." className="avatar-img rounded-circle" />
            </div>
            <div className="info">
              <a data-toggle="collapse" href="#collapseExample" aria-expanded="true">
               
                  <span>
                  {data[0].name}
                  <span>
                    {data[0].title}
                    {/* <span className="user-level">{data[0].logo}</span> */}
                    <span className="caret"></span>
                  </span>
                  </span>
              </a>
              <div className="clearfix"></div>

              <div className="collapse in" id="collapseExample">

                <ul className="nav">
                  {data[0].subTitle.map((subItem, index) => (

                    <li key={index}>
                      <Link to={subItem.slug}>
                        <span className="link-collapse">{subItem.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>


          <ul className="nav nav-primary">
            <li class="nav-item active">
              <Link to="/">
                <i class="fas fa-home"></i>
                <p>Dashboard</p>
              </Link>
            </li>
            <li class="nav-section">
              <span class="sidebar-mini-icon">
                <i class="fa fa-ellipsis-h"></i>
              </span>
              <h4 class="text-section">Components</h4>
            </li>

            {data.slice(1).map((item, index) => (
              <li className="nav-item" key={index}>
                <div>
                  <Link
                    data-toggle="collapse"
                    href={`#base${index}`}
                    onClick={() => toggleDropdown(index)}
                  >
                    <i className={item.logo}></i>
                    <p>{item.title}</p>
                    <span
                      className={`caret${activeDropdown === index ? ' open' : ''}`}
                    ></span>
                  </Link>

                  <div
                    className={`collapse${activeDropdown === index ? ' show' : ''}`}
                    id={`base${index}`}
                  >
                    {item.subTitle && item.subTitle.length > 0 && (
                      <ul className="nav nav-collapse">
                        {item.subTitle.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            <Link to={subItem.slug} className="dropdown-item">
                              <span className="sub-item">{subItem.title}</span>
                            </Link>
                          </li>
                         
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>

        </div>
      </div>
    </div>
  );
}

export default Sidebar;
