import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiAxios from "../../api/useApiAxios";
import { Context } from "../../context/Context"; // Import the Context
import moment from "moment";
import ReactPaginate from "react-paginate";
import Footer from "../footer/Footer";

const Payments = () => {
  const { userData } = useContext(Context); // Get userData from Context
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getAllPaymentApi, getMarketPrice, updateMarketPrice, searchAdminApi } = useApiAxios();
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [marketPrice, setMarketPrice] = useState("");
  const [newMarketPrice, setNewMarketPrice] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); 
  const [isSearching, setIsSearching] = useState(false); 

  useEffect(() => {
    const fetchPayments = async () => {
      setIsLoading(true);
      const { error, data } = await getAllPaymentApi();
      setIsLoading(false);

      if (!error) {
        setPayments(data.data);
      } else {
        console.log("error");
      }
    };

    fetchPayments();
  }, [userData]);

  const fetchMarketPrice = async () => {
    const { error, data } = await getMarketPrice();
    if (!error) {
      setMarketPrice(data.data.unit_amount);
    } else {
      console.log("error fetching market price");
    }
  };

  useEffect(() => {
    fetchMarketPrice();
  }, []);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(0);
  };

  const handleMarketPriceChange = (event) => {
    setNewMarketPrice(event.target.value);
  };

  const handleUpdateMarketPrice = async () => {
    const priceInCents = newMarketPrice;
    const formData = { price: priceInCents };
    const { error, data } = await updateMarketPrice(formData);

    if (!error) {
      setNewMarketPrice("");
      fetchMarketPrice(); // Fetch the updated market price
    } else {
      console.log("error updating market price");
    }
  };

  const handleSearch = async (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    setIsSearching(true);

    if (query === "") {
      setIsSearching(false);
    } else {
      try {
        const response = await searchAdminApi(query);
        setPayments(response.data.data);
        setCurrentPage(0);
      } catch (error) {
        console.error("Error searching:", error);
      }
    }
  };
  const offset = currentPage * rowsPerPage;
  const currentPayments = payments.slice(offset, offset + rowsPerPage);
  const pageCount = Math.ceil(payments.length / rowsPerPage);

  return (
    <>
      <div className="main-panel">
        <div className="content">
          <div className="panel-header">
            <div className="page-inner py-5">
              <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
                <div>
                  <h2 className="pb-2 fw-bold">Payment</h2>
                  <ul className="breadcrumbs">
                    <li className="nav-home">
                      <a href="#">
                        <i className="flaticon-home" />
                      </a>
                    </li>
                    <li className="separator">
                      <i className="flaticon-right-arrow" />
                    </li>
                    <li className="nav-item">
                      <a href="#">All Payment</a>
                    </li>
                    <li className="separator">
                      <i className="flaticon-right-arrow" />
                    </li>
                    <li className="nav-item">
                      <a href="#">Payment</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="page-inner mt--5">
            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h4 className="card-title">All Payment</h4>
                    <div className="form-inline">
                      <label htmlFor="marketPrice">Current Market Price: </label>
                      <input
                        type="text"
                        className="form-control mr-2"
                        placeholder="Market Price"
                        value={marketPrice / 100}
                        readOnly
                      />
                      <input
                        type="number"
                        className="form-control mr-2"
                        placeholder="Enter new price"
                        value={newMarketPrice}
                        onChange={handleMarketPriceChange}
                      />
                      <button className="btn btn-primary mr-2" onClick={handleUpdateMarketPrice}>
                        Update Price
                      </button>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        id="basic-datatables"
                        className="display table table-striped table-hover"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>PaymentFor</th>
                            <th>CreatedAt</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPayments.map((payment, index) => (
                            <tr key={index}>
                              <td>{payment?.admin?.name}</td>
                              <td>{payment?.admin?.email}</td>
                              <td>$ {payment?.price}</td>
                              <td>{payment?.status}</td>
                              <td>{payment?.paymentFor}</td>
                              <td>
                                {moment(payment?.createdAt).format("YYYY-MM-DD")}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pagination-container">
                      <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                      <div className="form-group d-flex align-items-center items-per-page" style={{ marginLeft: '20px' }}>
                        <label htmlFor="rowsPerPage" className="mr-2">Rows per page:</label>
                        <select
                          id="rowsPerPage"
                          className="form-control"
                          value={rowsPerPage}
                          onChange={handleRowsPerPageChange}
                          style={{ width: "auto", display: "inline-block" }}
                        >
                          <option value={5}>5</option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Payments;
