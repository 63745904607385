import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { FormGroup, FormLabel, FormControl, Button } from "react-bootstrap";
import * as Yup from "yup";

const PasswordResetForm = ({ changePasswordOTP, email }) => {
  const initialValues = {
    otp: "",
    password: "",
    passwordConfirm: "",
    email: email,
  };

  const validationSchema = Yup.object({
    otp: Yup.string().required("OTP is required"),
    password: Yup.string()
      .required("New Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must meet the requirements"
      ),
    passwordConfirm: Yup.string()
      .required("Confirm New Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      // Send PATCH request to the API
      const response = await changePasswordOTP({
        otp: values.otp,
        password: values.password,
        passwordConfirm: values.passwordConfirm,
        email,
      });
      console.log ("response",response)

      // Handle the response
      resetForm();
    } catch (error) {
      // Handle errors

      // Show error message
      alert("Error resetting password");
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <FormGroup className="mb-3">
          <FormLabel htmlFor="otp">OTP</FormLabel>
          <Field type="text" id="otp" name="otp" as={FormControl}    placeholder="Enter OTP"/>
          <ErrorMessage name="otp" component="div" className="text-danger" />
        </FormGroup>

        <FormGroup className="mb-3">
          <FormLabel htmlFor="newPassword">New Password</FormLabel>
          <Field
            type="password"
            id="password"
            name="password"
            as={FormControl}
            placeholder="Enter new password"

            
          />
          <ErrorMessage
            name="password"
            component="div"
            className="text-danger"
          />
        </FormGroup>

        <FormGroup className="mb-3">
          <FormLabel htmlFor="confirmNewPassword">
            Confirm New Password
          </FormLabel>
          <Field
            type="password"
            id="passwordConfirm"
            name="passwordConfirm"
            as={FormControl}
            placeholder="Confirm new password"
          />
          <ErrorMessage
            name="passwordConfirm"
            component="div"
            className="text-danger"
          />
        </FormGroup>

        <Button type="submit" className="btn btn-block btn_login w-100">
          Submit
        </Button>
      </Form>
    </Formik>
  );
};

export default PasswordResetForm;
