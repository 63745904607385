import { Outlet } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import jsonData from "../../data/sideBar.json";
import jsonData1 from "../../data/sideBar1.json";
import { Context } from "../../context/Context";
import './styles.css';  // Make sure to import your CSS file
import Footer from "../footer/Footer"
const Backend = () => {
  const [sideBarEnable, setSideBarEnable] = useState(true);
  const [sidebarData, setSidebarData] = useState(jsonData);
  const { userData } = useContext(Context);

  useEffect(() => {
    if (userData.role === "admin") {
      setSidebarData(jsonData);
    } else if (userData.role === "subAdmin") {
      setSidebarData(jsonData1);
    }
  }, [userData]);

  return (
    <>
    <div
      data-sidebar="light"
      data-layout-mode="light"
      className={sideBarEnable ? "sidebar-enable" : "vertical-collpsed"}
    >
      <div id="layout-wrapper">
        <Header
          sideBarEnable={sideBarEnable}
          setSideBarEnable={setSideBarEnable}
        />
        <Sidebar data={sidebarData} />
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-container">
    <div className="page-content">
    <div className="container-fluid">
    <Footer />
    </div>
    </div>
    </div>
   
  </>
  
  );
};

export default Backend;
